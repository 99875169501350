<template>
  <ion-header translucent>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="setClose"
          ><ion-icon :icon="closeCircleOutline"></ion-icon
        ></ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen>
    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>
          <ion-chip>
            <ion-avatar>
              <ion-img
                :src="profileImg"
                @ionError="
                  profileImg =
                    'https://ionicframework.com/docs/demos/api/avatar/avatar.svg'
                "
              ></ion-img>
            </ion-avatar>
            <ion-label>{{ modalData.writer }}</ion-label>
          </ion-chip>
        </ion-card-subtitle>
        <ion-card-title>{{ modalData.title }}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        담당자: {{ modalData.chrgr }}<br />
        담당자 연락처:
        <a :href="`tel:${modalData.chrgr_contact}`">{{ modalData.chrgr_contact }}</a
        ><br />
        주소: {{ modalData.contents }}
        <ion-grid>
          <ion-row v-if="route.params.instl_aprv_yn === 'Y'" class="l-photo">
            <ion-col size="6">
              <div class="photo">
                <ion-img
                  v-if="photo1"
                  :src="photo1"
                  @ionError="photo1 = '/assets/images/common/no-image.png'"
                ></ion-img>
              </div>
            </ion-col>
            <ion-col size="6">
              <div class="photo">
                <ion-img
                  v-if="photo2"
                  :src="photo2"
                  @ionError="photo2 = '/assets/images/common/no-image.png'"
                ></ion-img>
              </div>
            </ion-col>
            <ion-col size="6">
              <div class="photo">
                <ion-img
                  v-if="photo3"
                  :src="photo3"
                  @ionError="photo3 = '/assets/images/common/no-image.png'"
                ></ion-img>
              </div>
            </ion-col>
            <ion-col size="6">
              <div class="photo">
                <ion-img
                  v-if="photo4"
                  :src="photo4"
                  @ionError="photo4 = '/assets/images/common/no-image.png'"
                ></ion-img>
              </div>
            </ion-col>
          </ion-row>
          <ion-row v-else class="l-photo">
            <ion-col size="6">
              <ion-img
                v-if="photo1"
                :src="photo1"
                @ionError="photo1 = '/assets/images/common/no-image.png'"
                @click="handlePhoto1()"
              ></ion-img>
              <ion-button v-if="!photo1" @click="handlePhoto1()">
                <span>앞</span>
                <ion-icon :icon="cameraOutline"></ion-icon>
              </ion-button>
              <ion-button v-if="photo1" class="clear" @click="removePhoto1()"
                ><!-- <ion-icon :icon="cameraReverse"></ion-icon> --><ion-icon
                  :icon="trash"
                ></ion-icon
              ></ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-img
                v-if="photo2"
                :src="photo2"
                @ionError="photo2 = '/assets/images/common/no-image.png'"
                @click="handlePhoto2()"
              ></ion-img>
              <ion-button v-if="!photo2" @click="handlePhoto2()">
                <span>뒤</span>
                <ion-icon :icon="cameraOutline"></ion-icon>
              </ion-button>
              <ion-button v-if="photo2" class="clear" @click="removePhoto2()">
                <!-- <ion-icon :icon="cameraReverse"></ion-icon> -->
                <ion-icon
                  :icon="trash"
                ></ion-icon
              ></ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-img
                v-if="photo3"
                :src="photo3"
                @ionError="photo3 = '/assets/images/common/no-image.png'"
                @click="handlePhoto3()"
              ></ion-img>
              <ion-button v-if="!photo3" @click="handlePhoto3()">
                <span>좌</span>
                <ion-icon :icon="cameraOutline"></ion-icon>
              </ion-button>
              <ion-button v-if="photo3" class="clear" @click="removePhoto3()"
                ><!-- <ion-icon :icon="cameraReverse"></ion-icon> --><ion-icon
                  :icon="trash"
                ></ion-icon
              ></ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-img
                v-if="photo4"
                :src="photo4"
                @ionError="photo4 = '/assets/images/common/no-image.png'"
                @click="handlePhoto4()"
              ></ion-img>
              <ion-button v-if="!photo4" @click="handlePhoto4()">
                <span>우</span>
                <ion-icon :icon="cameraOutline"></ion-icon>
              </ion-button>
              <ion-button v-if="photo4" class="clear" @click="removePhoto4()"
                ><!-- <ion-icon :icon="cameraReverse"></ion-icon> --><ion-icon
                  :icon="trash"
                ></ion-icon
              ></ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-content>
  <ion-footer>
    <ion-button
      v-if="route.params.instl_aprv_yn !== 'Y'"
      expand="full"
      size="large"
      @click="setChange"
      >수정하기</ion-button
    >
  </ion-footer>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonChip,
  IonAvatar,
  IonImg,
  IonLabel,
  IonCardContent
} from '@ionic/vue'
import { defineComponent, reactive, ref, inject, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { closeCircleOutline, cameraOutline, cameraReverse, trash } from 'ionicons/icons'
import { Camera, CameraResultType } from '@capacitor/camera'
import { updateData } from '@/api/board/common'

export default defineComponent({
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonChip,
    IonAvatar,
    IonImg,
    IonLabel,
    IonCardContent
  },
  props: {
    title: {
      type: String,
      default: '상세'
    },
    modalData: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const eventBus = inject('$eventBus')
    const route = useRoute();

    let photo1 = ref(undefined)
    let photo2 = ref(undefined)
    let photo3 = ref(undefined)
    let photo4 = ref(undefined)
    let profileImg = ref(undefined)
    const apiUrl = '/api/ma/clientinfo/'

    onMounted(() => {
      if (props.modalData.profileImage) {
        // profileImg.value = reactive(props.modalData.profileImage)
        profileImg.value = reactive('https://ionicframework.com/docs/demos/api/avatar/avatar.svg')
      }

      if (props.modalData.img1) {
        photo1.value = reactive(props.modalData.img1)
      }

      if (props.modalData.img2) {
        photo2.value = reactive(props.modalData.img2)
      }

      if (props.modalData.img3) {
        console.log('img3')
        photo3.value = reactive(props.modalData.img3)
      }

      if (props.modalData.img4) {
        photo4.value = reactive(props.modalData.img4)
      }
    })

    const imgCompressOptions = ref({
      maxSizeMB: 1,
      maxWidthOrHeight: 200,
      useWebWorker: true
    })

    const handlePhoto1 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo1.value = image.dataUrl
    }

    const removePhoto1 = async () => {
      photo1.value = undefined
    }

    const handlePhoto2 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo2.value = image.dataUrl
    }

    const removePhoto2 = async () => {
      photo2.value = undefined
    }

    const handlePhoto3 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo3.value = image.dataUrl
    }

    const removePhoto3 = async () => {
      photo3.value = undefined
    }

    const handlePhoto4 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo4.value = image.dataUrl
    }

    const removePhoto4 = async () => {
      photo4.value = undefined
    }

    const setClose = () => {
      emit('close', false)
    }

    const setChange = async () => {
      eventBus.emit('loading', true, '저장중...')
      const data = {
        id: props.modalData.id,
        instl_aprv_yn: 'B',
        img1: window.btoa(photo1.value),
        img2: window.btoa(photo2.value),
        img3: window.btoa(photo3.value),
        img4: window.btoa(photo4.value)
      }

      await updateData(apiUrl, data)
      await reload()
      setClose()
    }

    const reload = async () => {
      await eventBus.emit('reload')
      await eventBus.emit('loading', false)
    }

    return {
      route,
      photo1,
      photo2,
      photo3,
      photo4,
      profileImg,
      imgCompressOptions,
      handlePhoto1,
      removePhoto1,
      handlePhoto2,
      removePhoto2,
      handlePhoto3,
      removePhoto3,
      handlePhoto4,
      removePhoto4,
      closeCircleOutline,
      cameraOutline,
      setClose,
      setChange,
      cameraReverse,
      trash
    }
  }
})
</script>

<style scoped>
:deep ion-card {
  box-shadow: none;
}
:deep ion-chip {
  margin-inline: 0;
}
:deep ion-card-header,
ion-card-content {
  padding: 0 10px;
}
:deep ion-card-content {
  padding-top: 10px;
}
.l-photo :deep ion-col {
  text-align: center;
  position: relative;
}
.l-photo :deep ion-button,
.l-photo .photo {
  width: 150px;
  height: 150px;
  --background: transparent;
  --background-focused: transparent;
  --background-hover: rgba(0, 0, 0, 0.3);
  border: 2px solid #33ccbd;
  border-radius: 5px;
  font-size: 50px;
  --color: #33ccbd;
}

.l-photo .photo {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.l-photo :deep ion-img {
  position: absolute;
  width: 145px;
  height: 145px;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.l-photo .clear {
  --color: rgb(209, 207, 207);
  font-size: 30px;
}

@media (max-width: 320px) {
  .l-photo {
    flex-direction: column;
  }
  .l-photo :deep ion-col,
  .l-photo .photo {
    width: 100% !important;
    max-width: 100% !important;
    flex: 1 !important;
  }
}
</style>
