<template>
  <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
    <ion-refresher-content 
      :pulling-icon="chevronDownCircleOutline"
      refreshing-spinner="circles"
      refreshing-text="로딩중..."
    ></ion-refresher-content>
  </ion-refresher>
  <ion-list>
    <ion-item-sliding>
      <template v-if="total > 0">
        <ion-item v-for="(item, index) in list.datas" :key="index" @click="detail(item)">
          <ion-avatar slot="start">
            <ion-img v-if="item.img1" :src="item.img1" @ionError="item.img1='/assets/images/common/no-image.png'"></ion-img>
            <ion-img v-else src="/assets/images/common/no-image.png"></ion-img>
          </ion-avatar>
          <ion-label>
            <h2>{{ item.store_nm }}</h2>
            <h3>{{ item.title }}</h3>
            <p v-html="item.contents"></p>
            <span v-if="item.chg_dt" class="date">{{ $moment(item.chg_dt).format('YYYY.MM.DD') }}</span>
          </ion-label>
        </ion-item>
      </template>
      <template v-else>
        <ion-item>
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h2>등록된 데이터가 없습니다.</h2>
          </ion-label>
        </ion-item>
        <!-- <ion-item>
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h2><ion-skeleton-text animated style="width: 60%"></ion-skeleton-text></h2>
            <h3><ion-skeleton-text animated style="width: 60%"></ion-skeleton-text></h3>
            <p><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
          </ion-label>
        </ion-item> -->
      </template>
    </ion-item-sliding>
    
    <ion-modal
      :is-open="isOpenRef"
      css-class="my-custom-class"
      @didDismiss="setOpen(false)"
    >
      <Modal :title="detailTitle" :modal-data="detailData" @close="setClose"></Modal>
    </ion-modal>
  </ion-list>

  <ion-infinite-scroll
    @ionInfinite="loadData($event)" 
    threshold="100px" 
    id="infinite-scroll"
    :disabled="!nextLoading"
  >
    <ion-infinite-scroll-content
      loading-spinner="bubbles"
      loading-text="로딩중...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</template>

<script>
import { 
  IonList,
  IonItemSliding,
  IonItem,
  IonAvatar,
  IonLabel,
  IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue'
import { defineComponent, ref, reactive, watch } from 'vue'
import { chevronDownCircleOutline } from 'ionicons/icons'
import Modal from './modal/ListDetailModal'

export default defineComponent({
  name: 'List',
  components: { 
    IonList,
    IonItemSliding,
    IonItem,
    IonAvatar,
    IonLabel,
    IonModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    Modal 
  },
  props: {
    name: {
      type: String,
      default: undefined
    },
    datas: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    pageNum: {
      type: Number,
      default: 0
    },
    nextLoading: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const isOpenRef = ref(false)
    const setOpen = (state) => isOpenRef.value = state

    let list = reactive({
      datas: []
    })
    let detailTitle = reactive(undefined)
    let detailData = ref({})

    const detail = (item) => {
      detailTitle = item.title

      detailData.value.id = item.id
      detailData.value.writer = item.writer
      detailData.value.title = item.title
      detailData.value.contents = item.contents
      detailData.value.profileImage = item.profileImage
      detailData.value.profile = item.profile
      detailData.value.chrgr = item.chrgr
      detailData.value.chrgr_contact = item.chrgr_contact
      detailData.value.img1 = item.img1
      detailData.value.img2 = item.img2
      detailData.value.img3 = item.img3
      detailData.value.img4 = item.img4
      
      setOpen(true)
    }
  
    watch(() => props.datas, (newVal) => {
      newVal.map(o => o.profileImage = require('./avatar-rey.png'))
      list.datas = newVal
    },
    { 
      deep: true 
    })

    const setClose = (state) => {
      isOpenRef.value = state
    }

    const pushData = () => {
      if (props.nextLoading) {
        emit('nextPage', Number(props.pageNum) + 1)
      }
    }

    const loadData = (ev) => {
      setTimeout(() => {
        if (props.nextLoading) {
          pushData()
        }
        ev.target.complete()
      }, 500)
    }

    const doRefresh = (event) => {
      setTimeout(() => {
        event.target.complete()
      }, 2000)
    }

    return { 
      isOpenRef, 
      setOpen, 
      list,
      detail,
      detailTitle,
      detailData,
      setClose,
      loadData,
      chevronDownCircleOutline,
      doRefresh
    }
  }
})
</script>

<style scoped>
.date{
  display: block;
  margin-top: 10px;
  font-size: 12px;
  color: #666;
  font-weight: 300;
}
:deep ion-avatar{
  --border-radius: 2px;
  width: 60px;
  height: 60px;
}
:deep ion-item{
  --min-height: 65px;
  --padding-start: 0;
  --inner-padding-end: 0;
  --border-color: transparent;
}
</style>