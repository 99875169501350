<template>
  <ion-content :fullscreen="true">
    <List
      name="Board"
      :datas="boardList"
      :total="total"
      :page-num="currentPageNum"
      :next-loading="disabled"
      @next-page="nextPage"
    />
  </ion-content>
</template>

<script>
import { IonContent } from '@ionic/vue'
import { defineComponent, ref, watch, inject, getCurrentInstance, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import List from '@/components/List'
import { listData } from '@/api/board/common'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'NewRequest',
  components: {
    IonContent,
    List
  },
  setup() {
    const eventBus = inject('$eventBus')
    const { proxy } = getCurrentInstance()

    const route = useRoute()
    const store = useStore()
    let token = store.state.User.token
    const userInfo = proxy.$jwtDecode(token)
    const userId = userInfo.user_id

    let total = ref(0)
    let currentPageNum = ref(1)
    let boardList = ref([])
    let nextLoading = ref(true)
    let chg_dv = ref('')

    watch(
      () => route.params,
      async () => {
        await getList()
      },
      {
        deep: true
      }
    )

    onMounted(async () => {
      await getList()

      eventBus.on('filter', (val) => {
        chg_dv.value = val
        getList()
      })

      eventBus.on('reload', () => {
        getList()
      })
    })

    const getList = async () => {
      if (nextLoading.value) {
        const apiUrl = '/api/ma/clientinfo/'
        let instl_aprv_yn = route.params.instl_aprv_yn
        if (instl_aprv_yn === 'ALL') {
          instl_aprv_yn = undefined
        }

        let params = {
          kor_instl_chrgr: userId,
          chg_dv: chg_dv.value,
          instl_aprv_yn,
          pageNum: currentPageNum.value,
          pageSize: 10000,
          ordering: 'asc'
        }
        const res = await listData(apiUrl, params)
        const datas = res.results.map((o) => {
          return {
            id: o.id,
            profileImage: o.profileImage,
            store_nm: o.store_nm,
            title: o.atm_nm,
            contents: o.rdnm_addr,
            chrgr: o.chrgr,
            chrgr_contact: o.chrgr_contact,
            writer: o.modifier,
            chg_dt: o.chg_dt,
            img1: o.img1 && window.atob(o.img1),
            img2: o.img2 && window.atob(o.img2),
            img3: o.img3 && window.atob(o.img3),
            img4: o.img4 && window.atob(o.img4)
          }
        })
        total.value = res.count
        if (currentPageNum.value === 1) {
          boardList.value = datas
        } else if (res.results) {
          boardList.value.push(...datas)
        } else if (!res.next) {
          nextLoading.value = false
        }
      }
    }

    const segmentChanged = async (ev) => {
      if (ev.detail.value) {
        chg_dv.value = ev.detail.value
        await getList()
      }
    }

    const nextPage = async (pageNum) => {
      if (pageNum > 1) {
        currentPageNum.value = pageNum
        await getList()
      }
    }

    return {
      boardList,
      total,
      currentPageNum,
      segmentChanged,
      nextPage
    }
  }
})
</script>

<style scoped>
:deep ion-avatar {
  --border-radius: 2px;
}

:deep ion-item .item-native {
  --padding-start: 0;
}
</style>
